<style lang="sass" >
  
  .v-textarea textarea
    padding: 10px!important

</style>
<template lang="pug">
div(v-if='Inicio').negro
  v-row.negro.mb-12
  v-container(v-if='Inicio' fluid).negro
    v-form.my-5
      v-row.my-12.d-none.d-md-block
        v-container
          v-row(align='center' justify='center')
            h1(v-html='Inicio.texto_contacto.titulo').text-center.font-weight-light.white--text
          v-row(align='center' justify='center').mt-5
            p(v-html='Inicio.texto_contacto.sub' style='line-height : 5px').text-center.font-weight-light.white--text.d-none.d-md-block
          v-row(align='center' justify='center')
            h2(v-html='Inicio.texto_contacto.sub' style='line-height : 10px').text-center.font-weight-light.d-md-none.white--text

      v-form(v-model='validación' autocomplete='false').pa-5.mt-12.d-none.d-md-block
        v-row(justify='center' align='start').fill-height
          v-col(cols=12 sm=5 ) 
            v-text-field(dark color='white' :rules='[Reglas.requerido, Reglas.solo_letras]' v-model='información["Nombre: "]' label='NOMBRE Y APELLIDOS:' )
            v-text-field(dark color='grey' :rules='[Reglas.requerido, Reglas.email_valido]' v-model='información["Correo electrónico: "]'  label='E-MAIL:'   )
            v-text-field(dark  color='grey' :rules='[Reglas.requerido, Reglas.solo_números]'  v-model='información["Teléfono: "]' label='TELÉFONO:' )
          //-pc
          v-col( sm=4).d-none.d-md-block.ml-12    
            v-row
              v-textarea(background-color="white"  placeholder='Mensaje' v-model='información.Mensaje' )
            v-row(align='center' justify='center')
              v-col.pl-0
                v-hover(v-slot="{ hover }")
                  v-btn(block :color='hover ? "secundario" :"primario"' dark style='border-radius: 0px 0 0 0px !important' 
                    @click='enviar()' :loading='enviando_correo') Enviar
              v-spacer
      //-Móvil
      v-row.mt-5.mb-3.d-md-none
        v-container
          v-row(align='center' justify='center')
            h2(v-html='Inicio.texto_contacto.titulo').text-center.font-weight-light.white--text
          v-row(align='center' justify='center').mt-5
            p(v-html='Inicio.texto_contacto.sub' style='line-height : 5px').text-center.font-weight-light.white--text.d-none.d-md-block
          v-row(align='center' justify='center')
            h2(v-html='Inicio.texto_contacto.sub' style='line-height : 10px').text-center.font-weight-light.d-md-none.white--text

      v-form(v-model='validación' autocomplete='false').pr-3.pl-3.d-md-none
        v-row(justify='center' align='start').fill-height
          v-col(cols=12 sm=5 ) 
            v-text-field(dark color='white' :rules='[Reglas.requerido, Reglas.solo_letras]' v-model='información["Nombre: "]' label='Nombre Y Apellidos:' )
            v-text-field(dark color='grey' :rules='[Reglas.requerido, Reglas.email_valido]' v-model='información["Correo electrónico: "]'  label='E-mail:'   )
            v-text-field(dark  color='grey' :rules='[Reglas.requerido, Reglas.solo_números]'  v-model='información["Teléfono: "]' label='Teléfono:' )
          v-col( sm=4).d-none.d-md-block.ml-12    
            v-row
              v-textarea(background-color="white" placeholder='Mensaje' v-model='información.Mensaje' )
            v-row(align='center' justify='center')
              v-col.pl-0
                v-btn(color='primario' style='border-radius: 0px' dark block) Enviar
              v-spacer
          v-col(cols=12).justify-center.aling-center      
            v-textarea(background-color="white" placeholder='Mensaje' v-model='información.Mensaje')
            p(v-html='Inicio.texto_contacto.text_box'   color='negro ').text-center
    

        v-row(justify='space-around' ).d-none.d-md-flex 
          v-hover(v-slot="{ hover }")
            v-btn(:color='hover ? "secundario" :"primario"' :dark='hover' style='border-radius: 0px 0 0 0px !important' :disabled='!validación || información["Forma de contacto"].length == 0' @click='enviar()').forma_boton.elevation-0
              span(v-if='hover' v-html='Inicio.texto_contacto.btn_enviar' ).text-center.white--text.mt-3.body-1
              span(v-else v-html='Inicio.texto_contacto.btn_enviar' ).text-center.black--text.mt-3.body-1

        v-row(justify='center' ).d-md-none.mb-12
          v-container
            v-btn(x-large block color="primario" dark 
            style='border-radius: 0px 0 0 0px !important' :loading='enviando_correo'
            @click='enviar()').elevation-0.secundario
              h2.text-center.white--text.body-1 Enviar

</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: ()=>({
    información: {
      "Forma de contacto": [],
    },
    validación: false,
    enviando_correo: false
  }),
  computed: {
    ...mapState({
      Reglas: ({Ayudas})=>Ayudas.reglas,
    }),
  },
  methods:{
    ...mapActions({
      enviar_correo_electronico: 'Contacto/enviar_correo_electronico',
      activar_snackbar: 'Ayudas/activar_snackbar',
    }),
    enviar(){
      const titulo = 'Información del contacto'
      const asunto = `Nuevo contacto`
      let cuerpo = `
      <table>`
      Object.keys(this.información).map( i => cuerpo+='<tr><td>' + i + '</td><td>' + this.información[i] + '</td></tr>')
      cuerpo += `</table>`
      const { configuracion_de_correo, correo_electronico} = this.Contacto
      this.enviando_correo = true
      this.enviar_correo_electronico({titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})
      .then( () => {
        this.activar_snackbar('Se ha enviado su mensaje. Nos pondremos en contacto lo más pronto posible.')
        this.enviando_correo = false
        this.información  = {
          "Forma de contacto": [],
        }
      })
    }
  },
  props:{
    fondo_claro: Boolean
  }
}
</script>
